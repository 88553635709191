import { memo } from "react";
import { SolutionCategoriesStyled } from "./styled/solutionCategories.styled";
import SolutionButons from "./solutionButtons";
import { imagesReplace } from "../../shared/utils/mixedControl";

const SolutionCategories = ({ solutionCategories, widgets }) => {
  const discoverSolutions = widgets?.find(
    (item) => item.code === "discoverSolutions"
  );

  return (
    <SolutionCategoriesStyled
      className="solutionCatgories"
      style={{
        backgroundImage: `linear-gradient(
          rgba(28, 59, 132, 0.7),
          rgba(17, 94, 221, 0.75)
        ), url(${imagesReplace(discoverSolutions?.imagePath)})`
      }}
    >
      <div className="solutionCatgories-container">
        <div className="solutionCatgories-container-title">
          <p>{discoverSolutions?.title}</p>
        </div>
        <div className="solutionCatgories-container-subTitle">
          <p>{discoverSolutions?.subTitle}</p>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: discoverSolutions?.description }}
          className="solutionCatgories-container-description"
        ></div>
        <div className="solutionCatgories-container-solutionButtons">
          <SolutionButons
            solutionCategories={solutionCategories}
            url={discoverSolutions?.url}
          />
        </div>
      </div>
    </SolutionCategoriesStyled>
  );
};

export default memo(SolutionCategories);
