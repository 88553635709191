import { Col, Image, Row } from "antd";
import { CategoriesStyled } from "./styled/solutionCategories.styled";
import { useCallback, useState } from "react";
import Icon from "../../shared/partnerComponents/icons/icon";
import useIconName from "../../shared/utils/hooks/useIconName";
import { useRouter } from "next/router";
import ButtonCustom from "../../shared/partnerComponents/button/buttonCustom";
import { useWindowSize } from "../../shared/utils/mixedControl";

const SolutionButons = ({ solutionCategories, url }) => {
  const router = useRouter();

  const handleSolutionCategories = useCallback(
    (item) => {
      //console.log(item, url);
      const { slugURL, id } = item || {};
      const SolutionCategoryID = id || "";
      const slug = slugURL || "";
      if (slug && url && SolutionCategoryID) {
        router.push({
          pathname: `${url}/${slug}`,
          query: { SolutionCategoryID },
        });
      }
    },
    [solutionCategories, url]
  );

  const breakpoint = useWindowSize().width > 719 ? true : false;
  return (
    <Row justify="center" gutter={[breakpoint ? 200 : 30, 30]}>
      {solutionCategories &&
        solutionCategories.map((category) => {
          const iconName = useIconName(category?.id);
          return (
            <Col
              key={category.id}
              style={{ display: "flex", justifyContent: "center" }}
              className="gutter-column"
              xs={8}
              sm={8}
              md={8}
              lg={8}
              xl={6}
              xxl={6}
            >
              <CategoriesStyled className="solutionCategory">
                <div className="solutionCategory-icons">
                  <Icon name={`icon-${iconName}`} id="icon" />
                </div>
                <div className="solutionCategory-title">
                  <p>{category?.name}</p>
                </div>
                <ButtonCustom
                  backgroundColor="transparent"
                  className="solutionCategory-button"
                  onClick={() => handleSolutionCategories(category)}
                  label="findSolution"
                />
              </CategoriesStyled>
            </Col>
          );
        })}
    </Row>
  );
};

export default SolutionButons;

/*
 <div  style={style}>
              <p style={{ color: "white" }}>{category.name}</p>
            </div>

*/
